import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import * as cx from './Solutions.module.scss'
import clsx from 'clsx'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import SvgCheckedCircleBlack from '../../../../svg/checkedCircleBlack.inline'
import { Button } from '../../../global/buttons/Buttons'
import useMedia, { media } from '../../../../hooks/useMedia'

const Solutions = ({ classKey, content }) => {
  const isDeviceTablet = useMedia(media.tablet)
  const isDevicePhone = useMedia(media.phone)
  const [isMobile, setIsMobile] = useState(false)
  const [isPhone, setIsPhone] = useState(false)
  const [isTablet, setIsTablet] = useState(false)

  useEffect(() => {
    setIsTablet(isDeviceTablet)
  }, [isDeviceTablet])

  useEffect(() => {
    setIsPhone(isDevicePhone)
  }, [isDevicePhone])

  useEffect(() => {
    setIsMobile(isDevicePhone || isDeviceTablet)
  }, [isDevicePhone, isDeviceTablet])

  const desktopTop =
    (classKey + 1) % 2 == 0 || (classKey === 4 && !isMobile) ? (
      <img
        src={extractImage(content.accent)}
        className={clsx(cx.accent, cx[`accent${classKey + 1}`])}
      />
    ) : (
      <div className={clsx(cx.gradient, cx[`gradient${classKey + 1}`])} />
    )

  const desktopBottom =
    (classKey + 1) % 2 == 0 || (classKey === 4 && !isMobile) ? (
      <div className={clsx(cx.gradient, cx[`gradient${classKey + 1}`])} />
    ) : (
      <img
        src={extractImage(content.accent)}
        className={clsx(cx.accent, cx[`accent${classKey + 1}`])}
      />
    )

  return (
    <section className={clsx(cx.wrapper, cx[`wrapper${classKey + 1}`])}>
      <div
        className={clsx(
          cx.container,
          cx[`container${classKey + 1}`],
          'container'
        )}
      >
        <div
          className={clsx(
            cx.imageContainer,
            cx[`imageContainer${classKey + 1}`]
          )}
        >
          {(isTablet || isPhone) && classKey !== 4 ? (
            <img
              src={extractImage(content.accent)}
              className={clsx(cx.accent, cx[`accent${classKey + 1}`])}
            />
          ) : (
            desktopTop
          )}

          <img
            src={extractImage(content.photo)}
            className={clsx(cx.image, cx[`image${classKey + 1}`])}
          />
        </div>
        <div
          className={clsx(cx.textContainer, cx[`textContainer${classKey + 1}`])}
        >
          {(isTablet || isPhone) && classKey !== 4 ? (
            <div className={clsx(cx.gradient, cx[`gradient${classKey + 1}`])} />
          ) : (
            desktopBottom
          )}
          <p
            className={clsx(cx.title, cx[`title${classKey + 1}`], 'heading-3')}
          >
            {content.title}
          </p>
          <p
            className={clsx(
              cx.description,
              cx[`description${classKey + 1}`],
              'subtitle-5'
            )}
          >
            {content.description}
          </p>
          <p
            className={clsx(
              cx.benefitsTitle,
              cx[`benefitsTitle${classKey + 1}`],
              'small-heading'
            )}
          >
            KEY BENEFITS:
          </p>

          <ul>
            {content.benefits.map((val, key) => (
              <li key={key} className={clsx('subtitle-5')}>
                <SvgCheckedCircleBlack className={cx.checkCircle} />{' '}
                <div>{val}</div>
              </li>
            ))}
          </ul>
          <Button large text="VIEW PACKAGES" />
        </div>
      </div>
    </section>
  )
}

Solutions.propTypes = {
  classKey: PropTypes.number,
  content: PropTypes.array,
}

export default Solutions
