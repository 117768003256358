// extracted by mini-css-extract-plugin
export var bannerContent = "RealEstateDigitalMarketing-module--banner-content--48c61";
export var bannerHeading = "RealEstateDigitalMarketing-module--banner-heading--d1f0b";
export var bannerImage = "RealEstateDigitalMarketing-module--banner-image--2116d";
export var bannerWrapper = "RealEstateDigitalMarketing-module--banner-wrapper--c832e";
export var breadcrumbs = "RealEstateDigitalMarketing-module--breadcrumbs--09801";
export var button = "RealEstateDigitalMarketing-module--button--84215";
export var description = "RealEstateDigitalMarketing-module--description--3a0ff";
export var formContainer = "RealEstateDigitalMarketing-module--form-container--1e9c0";
export var gradient = "RealEstateDigitalMarketing-module--gradient--01d12";
export var imageContainer = "RealEstateDigitalMarketing-module--image-container--66ac1";
export var title = "RealEstateDigitalMarketing-module--title--3de88";