export const pageData = {
  title: 'Digital Marketing',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'Digital Marketing',
      url: '/digital-marketing/',
    },
  ],
  uri: '/digital-marketing/',
  buttonsHeight: { desktop: 1880, tablet: 2200, phone: 2880 },
  lcf: {
    titleClassName: 'heading-2',
  },
}
