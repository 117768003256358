// extracted by mini-css-extract-plugin
export var button = "Cards-module--button--d01ba";
export var card1 = "Cards-module--card-1--05777";
export var cardWrapper = "Cards-module--card-wrapper--50b93";
export var container = "Cards-module--container--fde57";
export var image = "Cards-module--image--c5f43";
export var imageContainer = "Cards-module--image-container--efbd6";
export var playBtn = "Cards-module--play-btn--2233e";
export var subtitle = "Cards-module--subtitle--c9279";
export var textContainer = "Cards-module--text-container--a4ce1";
export var title = "Cards-module--title--66454";
export var wrapper = "Cards-module--wrapper--b304e";