import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import * as cx from './Cards.module.scss'
import clsx from 'clsx'
import ModalVideo from '../../../global/modal/Video'
import PlayButton from '../../../global/buttons/PlayButton'

const Card = ({
  url = { image, video },
  content = { title, description, buttonText },
  cardWrapper,
}) => {
  const modalVideoRef = useRef()

  return (
    <div className={clsx(cx.cardWrapper, cardWrapper)}>
      <ModalVideo url={url.video} childFunc={modalVideoRef} />

      <div className={cx.imageContainer}>
        <img src={url.image} alt="Card Image" className={cx.image} />
        {url.video && (
          <PlayButton
            wrapperClassName={cx.playBtn}
            onClick={() => modalVideoRef.current.openModal()}
          />
        )}
      </div>

      <div className={cx.textContainer}>
        <p className={clsx(cx.title, 'heading-3')}>{content.title}</p>
        <p className={clsx(cx.subtitle, 'subtitle-5')}>{content.description}</p>
        <button className={clsx('primary-button large', cx.button)}>
          {content.buttonText}
        </button>
      </div>
    </div>
  )
}

const Cards = ({ media }) => {
  return (
    <section className={clsx(cx.wrapper)}>
      <div className={clsx('container', cx.container)}>
        <Card url={media.card1} content={media.card1} cardWrapper={cx.card1} />
        <Card url={media.card2} content={media.card2} />
      </div>
    </section>
  )
}

Card.propTypes = {
  url: PropTypes.any,
  cardWrapper: PropTypes.any,
}

Cards.propTypes = {
  media: PropTypes.string,
  content: PropTypes.any,
}

export default Cards
