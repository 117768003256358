import { useStaticQuery, graphql } from 'gatsby'

const useRealEstateDigitalMarketingMetadata = () => {
  const query = useStaticQuery(graphql`
    query realEstateDigitalMarketingMetadata {
      BannerBG: file(
        relativePath: {
          eq: "real-estate-digital-marketing/banner/banner-bg.jpg"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      BannerImage: file(
        relativePath: {
          eq: "real-estate-digital-marketing/banner/banner-image.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      ArrowDown: file(
        relativePath: {
          eq: "real-estate-digital-marketing/marketing-solutions/arrow-down.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      SocialMedia: file(
        relativePath: {
          eq: "real-estate-digital-marketing/solutions/social-media.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      SearchEngine: file(
        relativePath: {
          eq: "real-estate-digital-marketing/solutions/search-engine.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      ProfessionalBlog: file(
        relativePath: {
          eq: "real-estate-digital-marketing/solutions/professional-blog.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      PPC: file(
        relativePath: { eq: "real-estate-digital-marketing/solutions/ppc.png" }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      CustomSolutions: file(
        relativePath: {
          eq: "real-estate-digital-marketing/solutions/custom-solutions.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      ContentDevelopment: file(
        relativePath: {
          eq: "real-estate-digital-marketing/solutions/content-development.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      Accent1: file(
        relativePath: {
          eq: "real-estate-digital-marketing/solutions/accent1.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      Accent2: file(
        relativePath: {
          eq: "real-estate-digital-marketing/solutions/accent2.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      Accent3: file(
        relativePath: {
          eq: "real-estate-digital-marketing/solutions/accent3.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      Accent4: file(
        relativePath: {
          eq: "real-estate-digital-marketing/solutions/accent4.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      Accent5: file(
        relativePath: {
          eq: "real-estate-digital-marketing/solutions/accent5.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      Accent6: file(
        relativePath: {
          eq: "real-estate-digital-marketing/solutions/accent6.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      GoalBG: file(
        relativePath: {
          eq: "real-estate-digital-marketing/marketing-goal/background-goal.jpg"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      GoalPhoto: file(
        relativePath: {
          eq: "real-estate-digital-marketing/marketing-goal/main-photo.png"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      Card1: file(
        relativePath: { eq: "real-estate-digital-marketing/cards/card-1.png" }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      Card2: file(
        relativePath: { eq: "real-estate-digital-marketing/cards/card-2.jpg" }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
      Madison: file(
        relativePath: {
          eq: "real-estate-digital-marketing/lead-form/Madison.jpg"
        }
      ) {
        id
        ...RealEstateDigitalMarketingImageOptimize
      }
    }

    fragment RealEstateDigitalMarketingImageOptimize on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }
  `)

  return query
}

export default useRealEstateDigitalMarketingMetadata
