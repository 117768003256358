import React from 'react'

const SvgCheckedCircleBlack = ({ className = '' }) => {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      style={{ enableBackground: 'new 0 0 22 22' }}
      className={`fill-current ${className}`}
      fill="none"
    >
      <title>CheckedCircleBlack</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 10.5C21.5 16.299 16.799 21 11 21C5.20101 21 0.5 16.299 0.5 10.5C0.5 4.70101 5.20101 0 11 0C16.799 0 21.5 4.70101 21.5 10.5ZM8.25152 9.52236C8.37963 9.57739 8.49549 9.65739 8.59235 9.75767L9.95 11.1153L13.4076 7.65767C13.5045 7.55739 13.6204 7.47739 13.7485 7.42236C13.8766 7.36734 14.0144 7.33837 14.1538 7.33716C14.2932 7.33595 14.4315 7.36251 14.5605 7.41531C14.6895 7.4681 14.8068 7.54607 14.9054 7.64466C15.0039 7.74324 15.0819 7.86048 15.1347 7.98952C15.1875 8.11856 15.2141 8.25682 15.2129 8.39624C15.2116 8.53566 15.1827 8.67344 15.1277 8.80155C15.0726 8.92965 14.9926 9.04551 14.8923 9.14237L10.6923 13.3424C10.4954 13.5392 10.2284 13.6498 9.95 13.6498C9.67157 13.6498 9.40455 13.5392 9.20765 13.3424L7.10765 11.2424C7.00736 11.1455 6.92737 11.0296 6.87234 10.9015C6.81731 10.7734 6.78835 10.6357 6.78713 10.4962C6.78592 10.3568 6.81249 10.2186 6.86528 10.0895C6.91808 9.96048 6.99605 9.84324 7.09463 9.74466C7.19322 9.64607 7.31045 9.5681 7.4395 9.51531C7.56854 9.46251 7.7068 9.43595 7.84622 9.43716C7.98564 9.43837 8.12342 9.46733 8.25152 9.52236Z"
        fill="#131314"
      />
    </svg>
  )
}

export default SvgCheckedCircleBlack
