// extracted by mini-css-extract-plugin
export var arrow = "MarketingGoal-module--arrow--72fd4";
export var caption = "MarketingGoal-module--caption--44b79";
export var container = "MarketingGoal-module--container--e6c2e";
export var headerContainer = "MarketingGoal-module--header-container--7bbe3";
export var image = "MarketingGoal-module--image--eaa4a";
export var imageContainer = "MarketingGoal-module--imageContainer--5d190";
export var listContainer = "MarketingGoal-module--list-container--9f363";
export var subtitle = "MarketingGoal-module--subtitle--28e5f";
export var title = "MarketingGoal-module--title--a57ff";
export var wrapper = "MarketingGoal-module--wrapper--44f68";