import React from 'react'
import * as cx from './MarketingSolutions.module.scss'
import clsx from 'clsx'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import useRealEstateDigitalMarketingMetadata from '../../../../hooks/real-estate-digital-marketing'

const MarketingSolutions = () => {
  const { ArrowDown } = useRealEstateDigitalMarketingMetadata()
  return (
    <section className={clsx(cx.wrapper)}>
      <div className={clsx('container', cx.container)}>
        <div className={clsx(cx.textContainer)}>
          <p className={clsx(cx.header, 'heading-5')}>
            End-to-End Real Estate Marketing Solutions
          </p>
          <p className={clsx(cx.subtitle1, 'subtitle-1')}>
            FOR AGENTS AND BROKERS
          </p>
          <hr className={cx.hr} />
          <p className={clsx(cx.subtitle5, 'subtitle-5')}>
            We’ll take care of growing your online presence so you can focus on
            what you do best: providing exemplary service to clients.
          </p>
          <img src={extractImage(ArrowDown)} className={cx.arrowDown}></img>
        </div>
      </div>
      <div className={cx.highlight}></div>
    </section>
  )
}

export default MarketingSolutions
