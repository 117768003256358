// extracted by mini-css-extract-plugin
export var accent = "Solutions-module--accent--b97d2";
export var accent1 = "Solutions-module--accent-1--0cce1";
export var accent2 = "Solutions-module--accent-2--8f060";
export var accent3 = "Solutions-module--accent-3--d224a";
export var accent4 = "Solutions-module--accent-4--124ab";
export var accent5 = "Solutions-module--accent-5--9183e";
export var accent6 = "Solutions-module--accent-6--81493";
export var benefitsTitle = "Solutions-module--benefits-title--893e9";
export var checkCircle = "Solutions-module--check-circle--ac481";
export var container = "Solutions-module--container--f7788";
export var container2 = "Solutions-module--container-2--964ed";
export var container4 = "Solutions-module--container-4--8ffa6";
export var container6 = "Solutions-module--container-6--69669";
export var description = "Solutions-module--description--cb309";
export var gradient = "Solutions-module--gradient--c89a9";
export var gradient1 = "Solutions-module--gradient-1--37642";
export var gradient2 = "Solutions-module--gradient-2--b45d4";
export var gradient3 = "Solutions-module--gradient-3--58c78";
export var gradient4 = "Solutions-module--gradient-4--b09e7";
export var gradient5 = "Solutions-module--gradient-5--9883e";
export var gradient6 = "Solutions-module--gradient-6--7e1fc";
export var image = "Solutions-module--image--7c209";
export var imageContainer = "Solutions-module--image-container--f8874";
export var imageContainer1 = "Solutions-module--image-container-1--61902";
export var imageContainer3 = "Solutions-module--image-container-3--a61a1";
export var imageContainer5 = "Solutions-module--image-container-5--1d4b7";
export var textContainer = "Solutions-module--text-container--b2826";
export var textContainer2 = "Solutions-module--text-container-2--d29df";
export var textContainer4 = "Solutions-module--text-container-4--a9cea";
export var textContainer6 = "Solutions-module--text-container-6--5c504";
export var title = "Solutions-module--title--70b71";
export var wrapper = "Solutions-module--wrapper--77277";
export var wrapper1 = "Solutions-module--wrapper-1--5d50c";
export var wrapper2 = "Solutions-module--wrapper-2--fdc2e";
export var wrapper3 = "Solutions-module--wrapper-3--5b26a";
export var wrapper4 = "Solutions-module--wrapper-4--b2847";
export var wrapper5 = "Solutions-module--wrapper-5--4c65f";
export var wrapper6 = "Solutions-module--wrapper-6--03ded";