import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import Banner from '../../components/global/banner/Banner'
import { getImage } from 'gatsby-plugin-image'
import { pageData as page } from '@src/data/RealEstateDigitalMarketing'
import useRealEstateDigitalMarketingMetadata from '@hooks/real-estate-digital-marketing'
import useMedia, { media } from '@hooks/useMedia'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import MarketingSolutions from '../../components/pages/real-estate-digital-marketing/marketing-solutions'
import Solutions from '../../components/pages/real-estate-digital-marketing/Solutions'
import * as cx from './RealEstateDigitalMarketing.module.scss'
import MarketingGoal from '../../components/pages/real-estate-digital-marketing/marketing-goal'
import Cards from '../../components/pages/real-estate-digital-marketing/cards'
import BlogPostsV2 from '../../components/global/blog/BlogPostsV2'
import LeadContentForm05 from '../../components/global/forms/LeadContactForm/LCF05'
import { useContactFormMetadata } from '../../hooks/contact-form-metadata'
import ModalForm from '../../components/global/modal/Form'
import FormSignUp from '../../components/global/forms/SignUp'

const RealEstateDigitalMarketing = () => {
  const isTablet = useMedia(media.tablet)
  const isPhone = useMedia(media.phone)
  const query = useContactFormMetadata()
  const {
    BannerBG,
    BannerImage,
    SearchEngine,
    SocialMedia,
    ProfessionalBlog,
    PPC,
    CustomSolutions,
    ContentDevelopment,
    Accent1,
    Accent2,
    Accent3,
    Accent4,
    Accent5,
    Accent6,
    GoalBG,
    GoalPhoto,
    Card1,
    Card2,
    Madison,
  } = useRealEstateDigitalMarketingMetadata()

  const solutionsImage = [
    [SearchEngine, Accent1],
    [SocialMedia, Accent2],
    [ProfessionalBlog, Accent3],
    [PPC, Accent4],
    [CustomSolutions, Accent5],
    [ContentDevelopment, Accent6],
  ]

  const contentSolutions = [
    {
      photo: SearchEngine,
      accent: Accent1,
      title: 'Search Engine Optimization (SEO)',
      description:
        'Did you know that the top three Google search results get 54% of all clicks? Our experts optimize your website for maximum search visibility so you can claim the lion’s share of web traffic.',
      benefits: [
        'Increase relevant traffic to your site',
        'Generate high-quality and high-intent leads',
        'Decrease your cost per acquisition',
      ],
    },
    {
      photo: SocialMedia,
      accent: Accent2,
      title: isPhone ? (
        <span>
          Social Media
          <br />
          Marketing
        </span>
      ) : (
        'Social Media Marketing'
      ),
      description:
        'Spend less of your day updating timelines and more of it doing client facetime. With our engaging posts, you can leave your social branding and lead generation on auto-pilot.',
      benefits: [
        isPhone ? (
          <span>
            Establish a strong presence on all
            <br />
            major social platforms
          </span>
        ) : (
          'Establish a strong presence on all major social platforms'
        ),
        'Drive traffic to your website and individual listings',
        'Use analytics to gain insights into prospective clients',
      ],
    },
    {
      photo: PPC,
      accent: Accent3,
      title: 'Pay-Per-Click (PPC) Advertising',
      description:
        'Why spend money on ads that people don’t engage with? With PPC, you only pay when someone clicks, meaning all your marketing budget goes to red-hot leads.',
      benefits: [
        'Maximize your marketing spend',
        'Create different campaigns targeting different audience segments',
        'Get real-time campaign insights via analytics',
      ],
    },
    {
      photo: ProfessionalBlog,
      accent: Accent4,
      title: isPhone ? (
        <span>
          Professional Blog
          <br />
          Writing
        </span>
      ) : (
        'Professional Blog Writing'
      ),
      description:
        'Websites that regularly publish high-quality content not only attract more web traffic, but also enjoy better search rankings. And that’s exactly what our blogging services can do for you.',
      benefits: [
        'Complement your SEO strategy',
        'Generate more organic web traffic',
        'Establish credibility and expertise',
      ],
    },
    {
      photo: ContentDevelopment,
      accent: Accent5,
      title: (
        <span>
          Content Development
          <br />
          (True Content)
        </span>
      ),
      description:
        'Your future clients crave information about the real estate market. We can create long-form content that provides in-depth answers to all their questions.',
      benefits: [
        'Drive web traffic to your website',
        'Improve your search engine visibility',
        'Highlight your mastery of real estate',
      ],
    },
    {
      photo: CustomSolutions,
      accent: Accent6,
      title: 'Custom Solutions',
      description:
        'Need one-off services like press release creation and distribution or online review management? We can tailor a plan specifically for you!',
      benefits: [
        'Pay only for what you need',
        'Choose to scale services up in the future',
        'Get the same VIP treatment as you would with our other plans',
      ],
    },
  ]

  const cardDetails = {
    card1: {
      image: extractImage(Card1),
      title: '10 Best Real Estate Marketing Websites',
      description:
        'Our portfolio of high-performing, award-winning work speaks for itself.',
      buttonText: 'see the list',
    },
    card2: {
      image: extractImage(Card2),
      title: (
        <span>
          High-Profile
          <br />
          Praise
        </span>
      ),
      description: (
        <span>
          20+ years of world-class service,
          <br /> 6,800+ five-star reviews.'
        </span>
      ),
      buttonText: 'see our clients’ reviews',
      video: 'https://www.youtube.com/watch?v=bLyLfN_m_1s&feature=youtu.be',
    },
  }

  const bannerDetails = {
    title: isTablet
      ? 'Proven Digital Marketing<br>For Real Estate Agents'
      : isPhone
      ? 'Proven Digital Marketing For<br/>Real Estate Agents'
      : 'Proven Digital Marketing For Real Estate Agents',
    subtitle: (
      <span>
        TAKE YOUR LEAD GENERATION
        <br />
        INTO OVERDRIVE
      </span>
    ),
    description: isPhone
      ? 'Have all your online touchpoints covered with our comprehensive but flexible digital marketing services.'
      : 'Have all your online touchpoints covered with<br/>our comprehensive but flexible digital marketing services.',
  }

  const modalContent = {
    title: 'Sign Up Today For A <br>Free Consultation',
    description:
      'See how Agent Image can help you grow your real estate business.',
    template: 'm2',
    type: 'a',
    submitLabel: 'Get Started Today',
  }

  const modalFunc = React.useRef()
  return (
    <LayoutInnerPage>
      <Seo title={page.title} uri={page.uri} />
      <Banner
        layout="product-intro-custom"
        wrapperClassName={cx.bannerWrapper}
        bgImage={extractImage(BannerBG)}
        bannerImage={extractImage(BannerImage)}
        bannerImageClassName={cx.bannerImage}
        title={bannerDetails.title}
        titleClassName={`${cx.title} heading-1`}
        breadcrumbs={page.breadcrumbs}
        breadcrumbsClass={cx.breadcrumbs}
        subtitle2={bannerDetails.subtitle}
        subtitle2ClassName={` subtitle-7`}
        headingClassName={cx.bannerHeading}
        contentClassName={cx.bannerContent}
        description={bannerDetails.description}
        descriptionClassName={`${cx.description} body-default`}
        button_text="I WANT MORE LEADS!"
        leftButtonClass={cx.button}
        button_click={(e) => modalFunc.current.openModal()}
      />

      <ModalForm
        childFunc={modalFunc}
        title={modalContent.title}
        description={modalContent.description}
        template={modalContent.template}
        type={modalContent.type}
      >
        <FormSignUp
          submitLabel={modalContent.submitLabel}
          messageLabel={modalContent.messageLabel}
        />
      </ModalForm>

      <MarketingSolutions />
      {contentSolutions.map((val, key) => (
        <Solutions
          key={key}
          images={solutionsImage[0]}
          content={val}
          classKey={key}
        />
      ))}
      <MarketingGoal
        backgroundImage={extractImage(GoalBG)}
        mainPhoto={extractImage(GoalPhoto)}
      />
      <Cards media={cardDetails} />
      <BlogPostsV2 />
      <LeadContentForm05
        layout="A"
        model={getImage(Madison)}
        title="Free Consultation"
        subtitle="BOOK YOUR"
        submitLabel={'BOOK MY CONSULTATION!'}
      />
    </LayoutInnerPage>
  )
}

export default RealEstateDigitalMarketing
