import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import * as cx from './MarketingGoal.module.scss'
import clsx from 'clsx'
import SvgArrowStraight from '../../../../svg/arrowStraight.inline'

const MarketingGoal = ({ backgroundImage, mainPhoto }) => {
  return (
    <section
      className={clsx(cx.wrapper)}
      style={{ background: `url(${backgroundImage})`, backgroundSize: 'cover' }}
    >
      <div className={cx.headerContainer}>
        <p className={clsx('subtitle-7', cx.subtitle)}>
          WHICH SERVICE IS RIGHT FOR YOU?
        </p>
        <p className={clsx('heading-5', cx.title)}>
          Tell Us Your Marketing Goal
        </p>
      </div>
      <div className={clsx(cx.container)}>
        <div className={cx.imageContainer}>
          <img src={mainPhoto} alt={'Goal Photo'} className={cx.image} />
          <p className={clsx(cx.caption, 'body-default')}>
            We've got the expertise to make your business goals a reality.
          </p>
        </div>
        <div className={cx.listContainer}>
          <ul>
            <li>
              <p>Lead Generation</p>
              <SvgArrowStraight className={cx.arrow} />
            </li>
            <li>
              <p>Search Engine Visibility</p>
              <SvgArrowStraight className={cx.arrow} />
            </li>
            <li>
              <p>Website Traffic</p>
              <SvgArrowStraight className={cx.arrow} />
            </li>
            <li>
              <p>Local Marketing</p>
              <SvgArrowStraight className={cx.arrow} />
            </li>
            <li>
              <p>Social Growth</p>
              <SvgArrowStraight className={cx.arrow} />
            </li>
            <li>
              <p>Brand Awareness</p>
              <SvgArrowStraight className={cx.arrow} />
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

MarketingGoal.propTypes = {
  backgroundImage: PropTypes.string,
  mainPhoto: PropTypes.string,
}

export default MarketingGoal
